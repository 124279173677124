import React from 'react';
import './style.css';
import {STORAGE_PREFIX} from "../../App";

const Forms = (props) => {
	return (
		<div className={'row'} style={{margin: 0}}>
			<div className={'container'}>
				<h3>Common Forms</h3>
				<div>
					Below are forms that we hope you will find useful during your journey with us. Please feel free to read
					and download them. Please reach out to management during normal business hours with questions and/or
					concerns.
				</div>
			</div>
			<div className={'container'}>
				<h3>Applicants</h3>
				<ul>
					<li >
						<span className={'clickable'}
							  onClick={() => {window.open(STORAGE_PREFIX + '/documents/Application Process & Qualification Standards.pdf')}}>
							Application Process & Qualification Standards
						</span>
					</li>
					<li >
						<span className={'clickable'}
							  onClick={() => {window.open(STORAGE_PREFIX + '/documents/Rental Application.pdf')}}>
							Rental Application
						</span>
					</li>
				</ul>
			</div>
			<div className={'container'}>
				<h3>New Tenants</h3>
				<ul>
					<li >
						<span className={'clickable'}
							  onClick={() => {window.open(STORAGE_PREFIX + '/documents/New Tenant Orientation.pdf')}}>
							New Tenant Orientation
						</span>
					</li>
					<li >
						<span className={'clickable'}
							  onClick={() => {window.open(STORAGE_PREFIX + '/documents/Rules and Regulations.pdf')}}>
							Rules and Regulations
						</span>
					</li>
					<li >
						<span className={'clickable'}
							  onClick={() => {window.open(STORAGE_PREFIX + '/documents/Move-in_Move-Out Condition Report.pdf')}}>
							Move-In / Move-out Checklist
						</span>
					</li>
				</ul>
			</div>
			<div className={'container'}>
				<h3>Moving Out</h3>
				<ul>
					<li>
						<span className={'clickable'}
							  onClick={() => {window.open(STORAGE_PREFIX + '/documents/Tenant Duty Checklist.pdf')}}>
							Tenant Duty Checklist
						</span>
					</li>
					<li>
						<span className={'clickable'}
							  onClick={() =>
							  {window.open(STORAGE_PREFIX + '/documents/Itemized List of Common Deposit Deductions.pdf')}}>
							Itemized List of Common Deposit Deductions
						</span>
					</li>
					<li>
						<span className={'clickable'}
							  onClick={() =>
							  {window.open(STORAGE_PREFIX + '/documents/Forwarding Address Form.pdf')}}>
							Forwarding Address Form
						</span>
					</li>
					<li>
						<span className={'clickable'}
							  onClick={() =>
							  {window.open(STORAGE_PREFIX + '/documents/Move-Out Survey.pdf')}}>
							Move-Out Survey
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Forms;
