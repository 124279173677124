import React, {Component} from 'react';
import {doApiRequest} from "../../api/requests";
import {TENANT_LIST, UNIT_DETAILS} from "./schema";
import PrimaryDetails from "../../containers/common/PrimaryDetails";
import List from "../../containers/common/List";
import {v4 as uuidv4} from "uuid";
import CustomModal from "../../containers/common/Modal";


class Unit extends Component {

    state = {
        unit: {
            pk: '',
            data: '',
            num_bedrooms: '',
            occupied: '',
            rent: '',
            property_address: ''
        },
        tenantList: [],
        newTenant: {
            data: '',
            mobile: '',
            email: '',
            notifications: 'mobile'
        },
        showModal: false
    }

    componentDidMount = async () => {
        await this.getUnit();
        await this.getTenantList()
    }

    async getUnit() {
        try {
            const unitId = this.props.match.params['unitId']
            const body = {...UNIT_DETAILS};
            body['content']['ExpressionAttributeValues'][':pk'] = unitId;
            const response = await doApiRequest(body);
            if (200 === response.status) {
                this.setState({unit: response.data.data[0]})
            }
        } catch (e) {
            console.log(e)
        }
    }

    async getTenantList() {
        try {
            const body = {...TENANT_LIST};
            body['content']['ExpressionAttributeValues'][':sk'] = this.state.unit.pk;
            const response = await doApiRequest(body);
            if (200 === response.status) {
                this.setState({tenantList:  response.data.data})
            }
        } catch (e) {
            console.log(e)
        }
    }

    onChange = (e, field) => {
        const newState = {...this.state};
        newState['newTenant'][field] = e.target.value;
        this.setState(newState)
    }

    submitHandler = async (e) => {
        e.preventDefault()
        try {
            const createTenant = {
                operation: 'put',
                content: {...this.state.newTenant}
            }
            createTenant['content']['pk'] = 'TE-' + uuidv4()
            createTenant['content']['sk'] = this.state.unit.pk
            createTenant['content']['entity_type'] = 'TE'
            createTenant['content']['property_address'] = this.state.unit.property_address
            createTenant['content']['property_unit'] = this.state.unit.data
            const response = await doApiRequest(createTenant);
            if (201 === response.status) {
                await this.getTenantList()
            }
        } catch (e) {
            console.log(e)
        }
        this.setState({showModal: false})
    }


    render() {
        return <div>
            <CustomModal
                title={'New Tenant'}
                showModal={this.state.showModal}
                closeModal={() => this.setState({showModal: false})}
                submitHandler={this.submitHandler}
                data={this.state.newTenant}
                onChange={this.onChange}
            />
            <PrimaryDetails details={this.state.unit} title={'Unit Details'}/>
            <List
                items={this.state.tenantList}
                title={'Tenant List'}
                columns={['data', 'mobile', 'email', 'notifications']}
                baseHref={'/tenants/'}
                id={'pk'}
                openModal={() => this.setState({showModal: true})}
            />
        </div>
    }
}

export default Unit;
