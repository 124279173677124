import React from "react";
import "./style.css"
import {toTitleCase} from "../../utils/StringUtil";

const PrimaryDetails = (props) => {
    const columnLength = 'col-lg-' + Math.floor(12 / Object.keys(props.details).length)
    return <div className={'container'}>
        <h4 className={'title'}>{props.title}</h4>
        <div className={'row container-inner'}>
            {Object
                .entries(props.details)
                .filter(entry => entry[0] !== 'pk' && entry[0] !== 'sk')
                .map(entry => {
                    return <div className={columnLength + ' col-lg-2 col-6 primary-details-entry'} key={entry[0]}>
                        <div><b>{toTitleCase(entry[0])}</b></div>
                        <div>{
                            typeof entry[1] === 'boolean' ?
                                entry[1] ?
                                    'yes' :
                                    'no' :
                                entry[0] === 'tenant_mobile' || entry[0] === 'mobile' ?
                                    <a href={'sms:+1'+entry[1]}>{entry[1]}</a> :
                                    entry[1]}</div>
                    </div>
                })}
        </div>
    </div>
}

export default PrimaryDetails;
