import React, {Component} from 'react';
import {doApiRequest} from "../../api/requests";
import {LIST, TENANT_DETAILS} from "./schema";
import {withRouter} from "react-router";
import PrimaryDetails from "../../containers/common/PrimaryDetails";
import List from "../../containers/common/List";
import {v4 as uuidv4} from "uuid";
import CustomModal from "../../containers/common/Modal";
import moment from "moment";
import {UPDATE_TASK} from "../Tasks/schema";

class Tenant extends Component {

    state = {
        tenant: {
            data: '',
            mobile: '',
            email: '',
            notifications: '',
            property_address: '',
            property_unit: ''
        },
        leaseList: [],
        taskList: [],
        newLease: {
            lease_start: '',
            number_of_months: '',
            rent: ''
        },
        newTask: {
            due_date: moment().format('YYYY-MM-DD'),
            description: ''
        },
        datePicker: {
            focus: false,
            date: moment()
        },
        showLeaseModal: false
    }

    componentDidMount = async () => {
        await this.getTenant();
        await this.getLists();
    }

    async getTenant() {
        try {
            const tenantId = this.props.match.params['tenantId']
            const body = {...TENANT_DETAILS};
            body['content']['ExpressionAttributeValues'][':pk'] = tenantId;
            const response = await doApiRequest(body);
            if (200 === response.status) {
                this.setState({tenant: response.data.data[0]})
            }
        } catch (e) {
            console.log(e)
        }
    }

    async getLists() {
        try {
            const body = {...LIST};
            body['content']['ExpressionAttributeValues'][':sk'] = this.state.tenant.pk;
            const response = await doApiRequest(body);
            if (200 === response.status) {
                const leaseList = [];
                const taskList = [];
                for (let item of response.data.data) {
                    if (item['entity_type'] === 'LE') {
                        leaseList.push(item)
                    } else if (item['entity_type'] === 'TA'){
                        taskList.push(item)
                    }
                }
                this.setState({leaseList: leaseList, taskList: taskList})
            }
        } catch (e) {
            console.log(e)
        }
    }

    onLeaseChange = (e, field) => {
        const newState = {...this.state};
        newState['newLease'][field] = e.target.value;
        this.setState(newState)
    }

    onTaskChange = (e, field) => {
        const newState = {...this.state};
        newState['newTask'][field] = e.target.value;
        this.setState(newState)
    }

    handleDateChange = (value) => {
        const newState = {...this.state};
        newState['datePicker']['date'] = value
        newState['newTask']['due_date'] = value.format('YYYY-MM-DD')
        this.setState(newState);
    }

    handleDateFocusChange = (value) => {
        const newState = {...this.state};
        newState['datePicker']['focus'] = value
        this.setState(newState);
    }

    submitLeaseHandler = async (e) => {
        e.preventDefault()
        try {
            const createLease = {
                operation: 'put',
                content: {...this.state.newLease}
            }
            const numMonths = parseInt(this.state.newLease['number_of_months'])
            createLease['content']['lease_end'] =
                moment(this.state.newLease['lease_start'], 'MM/DD/YYYY')
                    .add(numMonths, 'month')
                    .subtract(1, 'day')
                    .format('MM/DD/YYYY')
            createLease['content']['pk'] = 'LE-' + uuidv4()
            createLease['content']['sk'] = this.state.tenant.pk
            createLease['content']['data'] = this.state.tenant.data
            createLease['content']['tenant_mobile'] = this.state.tenant.mobile
            createLease['content']['entity_type'] = 'LE'
            createLease['content']['property_address'] = this.state.tenant.property_address
            createLease['content']['property_unit'] = this.state.tenant.property_unit
            const response = await doApiRequest(createLease);
            if (201 === response.status) {
                await this.getLists();
                for (let month = 0; month < numMonths; month++) {
                    const date = moment(this.state.newLease['lease_start'], 'MM/DD/YYYY').add(month, 'month')
                    const createTransaction = {
                        operation: 'put',
                        content: {
                            pk: 'TR-' + uuidv4(),
                            sk: createLease['content']['pk'],
                            entity_type: 'TR',
                            data: 'OPEN#' + date.format('YYYY-MM-DD'),
                            amt_due: this.state.newLease['rent'],
                            tenant_name: this.state.tenant.data,
                            tenant_mobile: this.state.tenant.mobile,
                            property_address: this.state.tenant.property_address,
                            property_unit: this.state.tenant.property_unit,
                            reason: date.format('MMMM YYYY') + ' Rent'
                        }
                    }
                    await doApiRequest(createTransaction);
                }
            }
        } catch (e) {
            console.log(e)
        }
        this.setState({showLeaseModal: false})
    }

    submitTaskHandler = async (e) => {
        e.preventDefault()
        try {
            const createTask = {
                operation: 'put',
                content: {...this.state.newTask}
            }
            createTask['content']['data'] = 'OPEN#' + createTask['content']['due_date']
            createTask['content']['pk'] = 'TA-' + uuidv4();
            createTask['content']['sk'] = this.state.tenant.pk;
            createTask['content']['entity_type'] = 'TA'
            createTask['content']['property_address'] = this.state.tenant.property_address
            const response = await doApiRequest(createTask);
            if (201 === response.status) {
                await this.getLists();
            }
        } catch (e) {
            console.log(e)
        }
        this.setState({showTaskModal: false})
    }

    updateItemHandler = async (item) => {
        try {
            const update = {...UPDATE_TASK};
            update['content']['Key']['pk'] = item['pk']
            update['content']['Key']['sk'] = item['sk']
            update['content']['ExpressionAttributeValues'][':data'] = 'COMPLETE#' + moment().format('YYYY-MM-DD')
            const response = await doApiRequest(update);
            if (200 === response.status) {
                const taskList = [...this.state.taskList]
                const index = taskList.findIndex(t => t['pk'] === item['pk'] && t['sk'] === item['sk'])
                taskList[index].data = update['content']['ExpressionAttributeValues'][':data']
                this.setState({taskList: taskList});
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return <div>
            <CustomModal
                title={'New Lease'}
                showModal={this.state.showLeaseModal}
                closeModal={() => this.setState({showLeaseModal: false})}
                submitHandler={this.submitLeaseHandler}
                data={this.state.newLease}
                onChange={this.onLeaseChange}
            />
            <CustomModal
                title={'New Task'}
                showModal={this.state.showTaskModal}
                closeModal={() => this.setState({showTaskModal: false})}
                submitHandler={this.submitTaskHandler}
                data={this.state.newTask}
                onChange={this.onTaskChange}
                date={this.state.datePicker.date}
                focused={this.state.datePicker.focus}
                handleDateChange={this.handleDateChange}
                handleDateFocusChange={this.handleDateFocusChange}
            />
            <PrimaryDetails details={this.state.tenant} title={'Tenant Details'}/>
            <List
                items={this.state.leaseList}
                title={'Lease List'}
                columns={['data', 'rent', 'lease_start', 'lease_end']}
                baseHref={'/leases/'}
                id={'pk'}
                openModal={() => this.setState({showLeaseModal: true})}
            />
            <List
                items={this.state.taskList}
                title={'Tasks'}
                columns={['data', 'description']}
                updateItem={this.updateItemHandler}
                openModal={() => this.setState({showTaskModal: true})}
            />
        </div>

    }
}

export default withRouter(Tenant);
