export const TENANT_DETAILS = {
        operation: "query",
        content: {
            ProjectionExpression: "pk, sk, #data, mobile, email, notifications, property_address, property_unit",
            ExpressionAttributeNames: {
                "#data": "data"
            },
            KeyConditionExpression: "pk = :pk",
            ExpressionAttributeValues: {
                ":pk": null
            }
        }
    }


export const LIST = {
    operation: "query",
    content: {
        ProjectionExpression: "pk, sk, #data, lease_start, lease_end, rent, description, entity_type",
        ExpressionAttributeNames: {
            "#data": "data"
        },
        KeyConditionExpression: "sk = :sk",
        ExpressionAttributeValues: {
            ":sk": null
        },
        IndexName: "gsi-1"
    }
}
