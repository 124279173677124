export const UNIT_LIST = {
    operation: "query",
    content: {
        ProjectionExpression: "pk, sk, #data, #status, city, property_address, sq_ft, num_bedrooms, " +
            "num_bathrooms, rent, asset_locations",
        ExpressionAttributeNames: {
            "#data": "data",
            "#status":"status"
        },
        KeyConditionExpression: "entity_type = :entity_type",
        ExpressionAttributeValues: {
            ":entity_type": "UN",
        },
        IndexName: "entity_type-gsi"
    }
};

