import React, {Component} from 'react';
import CustomModal from "../../containers/common/Modal";
import {withRouter} from "react-router";
import {doAuthRequest} from "../../api/requests";

class Login extends Component {

	state = {
		showModal: true,
		data: {
			username: '',
			password: ''
		}
	}

	onChange = (e, field) => {
		const newState = {...this.state};
		newState['data'][field] = e.target.value;
		this.setState(newState)
	}

	submitHandler = async (e) => {
		e.preventDefault()
		try {
			const data = {
				USERNAME: this.state.data.username,
				PASSWORD: this.state.data.password,
				operation: 'login'
			};
			const response = await doAuthRequest(data);
			if (200 === response.status) {
				const authData = response.data.data
				localStorage.setItem("ID_TOKEN", authData.AuthenticationResult.IdToken);
				localStorage.setItem("REFRESH_TOKEN", authData.AuthenticationResult.RefreshToken);
				this.props.history.goBack()
			}
		} catch (e) {
			console.log(e)
		}
		this.setState({showModal: false})
	}

	render() {
		return <div>
			<CustomModal
				title={'Admin Login'}
				showModal={this.state.showModal}
				closeModal={() => this.props.history.goBack()}
				submitHandler={this.submitHandler}
				data={this.state.data}
				onChange={this.onChange}
				submitText={'Login'}
			/>
		</div>
	}
}

export default withRouter(Login);
