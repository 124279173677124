import React, {Component} from 'react';
import {doApiRequest} from "../../api/requests";
import {UNIT_LIST} from "./schema";
import {withRouter} from "react-router";
import {STORAGE_PREFIX} from "../../App";
import './style.css';
import moment from "moment";

class Listings extends Component {

	state = {
		unitList: []
	}

	componentDidMount = async () => {
		await this.getUnitList();
	}

	async getUnitList() {
		try {
			const response = await doApiRequest(UNIT_LIST);
			if (200 === response.status) {
				this.setState({unitList: response.data.data});
			}
		} catch (e) {
			console.log(e)
		}
	}

	getListingElement = (item) => {
		return (
		<div className={'col-xl-3 col-lg-4 col-md-6 col-12'}>
			<div className={'container listing'}
				 onClick={() => this.props.history.push(`/listings/${item['pk']}`)}>
			<div className={'listing-img'}
				 style={{
				 	backgroundImage: `url(${STORAGE_PREFIX}/images/${item['asset_locations'][0]})`}}>
				<div className={'col-12 mt-1 pt-1'}>
					{this.getStatusBadge(item['status'])}
				</div>
			</div>
			<div className={'listing-rent ml-1'}>
				{'$'+ item['rent']}
			</div>
			<div className={'d-flex ml-1'}>
				<div>
					<b>{item['num_bedrooms']}</b> bed
				</div>&nbsp;&nbsp;&nbsp;
				<div>
					<b>{item['num_bathrooms']}</b> bath
				</div>&nbsp;&nbsp;&nbsp;
				<div>
					<b>{item['sq_ft']}</b> sqft
				</div>
			</div>
			<div className={'ml-1'}>
				{item['property_address'] + ' - ' + item['data']}
			</div>
			<div className={'ml-1'}>
				{item['city']}
			</div>
			</div>
		</div>);
	}

	getStatusBadge = (status) => {
		const statusParts = status.split('#')
		if (statusParts[0] === 'INACTIVE') {
			return <div className={'status-badge-sm status-badge-sm-inactive'}>
				Unavailable
			</div>
		} else if (statusParts[0] === 'ACTIVE' && moment(statusParts[1], 'YYYY-MM-DD') > moment()) {
			return <div className={'status-badge-sm status-badge-sm-inactive'}>
				Available {moment(statusParts[1], 'YYYY-MM-DD').format('MM/DD/YYYY')}
			</div>
		} else if (statusParts[0] === 'ACTIVE' && moment(statusParts[1], 'YYYY-MM-DD') <= moment()) {
			return <div className={'status-badge-sm status-badge-sm-active'}>
				Available Now
			</div>
		} else {
			return <div/>
		}
	}

	render() {
		const units = [...this.state.unitList];
		units.sort((a,b) =>
			(`${a.property_address} ${a.data}` > `${b.property_address} ${b.data}`) ? 1 :
				((`${b.property_address} ${b.data}` > `${a.property_address} ${a.data}`) ? -1 : 0))
		return <div className={'row no-gutters'}>
				{units.map(item => this.getListingElement(item))}
		</div>
	}
}

export default withRouter(Listings);
