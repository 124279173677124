import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/Home";
import Properties from "./components/Properties";
import NavBar from "./containers/nav/NavBar";
import Property from "./components/Property";
import Unit from "./components/Unit";
import Tenant from "./components/Tenant";
import Lease from "./components/Lease";
import Contact from "./components/Contact";
import Payments from "./components/Payments";
import Forms from "./components/Forms";
import Login from "./components/Login";
import Transactions from "./components/Transactions";
import Tasks from "./components/Tasks";
import Footer from "./containers/footer/Footer";
import Listings from "./components/Listings";
import Listing from "./components/Listing";

export const STORAGE_PREFIX = 'https://ianballardproperties-public-storage.s3.amazonaws.com'


function App() {
    return (
        <div className="App">

            <div id={'content-wrap'}>
                <div id={'loader'} className={'hide-loader'}>
                    <i className="fa fa-spinner fa-spin"></i>
                </div>
                <Router>
                    <NavBar />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/admin" component={Login} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/payments" component={Payments} />
                        <Route exact path="/forms" component={Forms} />
                        <Route exact path="/listings/:listingId" component={Listing} />
                        <Route exact path="/listings" component={Listings} />

                        {localStorage.getItem("ID_TOKEN") &&
                            <Route exact path="/properties" component={Properties} />}
                        {localStorage.getItem("ID_TOKEN") &&
                            <Route exact path="/transactions" component={Transactions} />}
                        {localStorage.getItem("ID_TOKEN") &&
                            <Route exact path="/tasks" component={Tasks} />}
                        {localStorage.getItem("ID_TOKEN") &&
                            <Route exact path="/properties/:propertyId" component={Property} />}
                        {localStorage.getItem("ID_TOKEN") &&
                            <Route exact path="/units/:unitId" component={Unit} />}
                        {localStorage.getItem("ID_TOKEN") &&
                            <Route exact path="/tenants/:tenantId" component={Tenant} />}
                        {localStorage.getItem("ID_TOKEN") &&
                            <Route exact path="/leases/:leaseId" component={Lease} />}
                    </Switch>
                </Router>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
