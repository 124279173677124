export const UNIT_DETAILS = {
    operation: "query",
    content: {
        ProjectionExpression: "pk, sk, #data, num_bedrooms, occupied, property_address",
        ExpressionAttributeNames: {
            "#data": "data"
        },
        KeyConditionExpression: "pk = :pk",
        ExpressionAttributeValues: {
            ":pk": null
        }
    }
};

export const TENANT_LIST = {
    operation: "query",
    content: {
        ProjectionExpression: "pk, sk, #data, mobile, email, notifications",
        ExpressionAttributeNames: {
            "#data": "data"
        },
        KeyConditionExpression: "sk = :sk",
        ExpressionAttributeValues: {
            ":sk": null
        },
        IndexName: "gsi-1"
    }
};
