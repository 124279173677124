import React, {Component} from 'react';
import {doApiRequest} from "../../api/requests";
import {LEASE_DETAILS, TRANSACTION_LIST} from "./schema";
import {withRouter} from "react-router";
import PrimaryDetails from "../../containers/common/PrimaryDetails";
import List from "../../containers/common/List";
import {v4 as uuidv4} from "uuid";
import CustomModal from "../../containers/common/Modal";
import moment from "moment";
import {UPDATE_TRANSACTION} from "../Transactions/schema";

class Lease extends Component {

    state = {
        lease: {
            data: '',
            rent: '',
            lease_start: '',
            lease_end: '',
            tenant_mobile: '',
            property_address: '',
            property_unit: ''
        },
        transactionList: [],
        newTransaction: {
            amt_due: '',
            reason: '',
        },
        showModal: false
    }

    componentDidMount = async () => {
        await this.getLease();
        await this.getTransactionList();
    }

    async getLease() {
        try {
            const leaseId = this.props.match.params['leaseId']
            const body = {...LEASE_DETAILS};
            body['content']['ExpressionAttributeValues'][':pk'] = leaseId;
            const response = await doApiRequest(body);
            if (200 === response.status) {
                this.setState({lease: response.data.data[0]})
            }
        } catch (e) {
            console.log(e)
        }
    }

    async getTransactionList() {
        try {
            const body = {...TRANSACTION_LIST};
            body['content']['ExpressionAttributeValues'][':sk'] = this.state.lease.pk;
            const response = await doApiRequest(body);
            if (200 === response.status) {
                this.setState({transactionList:  response.data.data})
            }
        } catch (e) {
            console.log(e)
        }
    }

    onChange = (e, field) => {
        const newState = {...this.state};
        newState['newTransaction'][field] = e.target.value;
        this.setState(newState)
    }

    submitHandler = async (e) => {
        e.preventDefault()
        try {
            const createTransaction = {
                operation: 'put',
                content: {...this.state.newTransaction}
            }
            createTransaction['content']['pk'] = 'TR-' + uuidv4()
            createTransaction['content']['sk'] = this.state.lease.pk
            createTransaction['content']['entity_type'] = 'TR'
            createTransaction['content']['data'] = 'OPEN#' + moment().format('YYYY-MM-DD')
            createTransaction['content']['tenant_name'] = this.state.lease.data
            createTransaction['content']['tenant_mobile'] = this.state.lease.tenant_mobile
            createTransaction['content']['property_address'] = this.state.lease.property_address
            createTransaction['content']['property_unit'] = this.state.lease.property_unit
            const response = await doApiRequest(createTransaction);
            if (201 === response.status) {
                await this.getTransactionList();
            }
        } catch (e) {
            console.log(e)
        }
        this.setState({showModal: false})
    }

    updateItemHandler = async (item) => {
        try {
            const update = {...UPDATE_TRANSACTION};
            update['content']['Key']['pk'] = item['pk']
            update['content']['Key']['sk'] = item['sk']
            update['content']['ExpressionAttributeValues'][':data'] = 'PAID#' + moment().format('YYYY-MM-DD')
            const response = await doApiRequest(update);
            if (200 === response.status) {
                const transactionList = [...this.state.transactionList]
                const index = transactionList.findIndex(t => t['pk'] === item['pk'] && t['sk'] === item['sk'])
                transactionList[index].data = update['content']['ExpressionAttributeValues'][':data']
                this.setState({transactionList: transactionList});
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return <div>
            <CustomModal
                title={'New Transaction'}
                showModal={this.state.showModal}
                closeModal={() => this.setState({showModal: false})}
                submitHandler={this.submitHandler}
                data={this.state.newTransaction}
                onChange={this.onChange}
            />
            <PrimaryDetails details={this.state.lease} title={'Lease Details'}/>
            <List
                items={this.state.transactionList}
                title={'Transaction List'}
                columns={['data', 'amt_due', 'amt_paid', 'payment_method']}
                updateItem={this.updateItemHandler}
                openModal={() => this.setState({showModal: true})}
            />
        </div>

    }
}

export default withRouter(Lease);
