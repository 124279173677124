import React from 'react';
import './style.css';

const Payments = (props) => {
	return (
		<div className={'row'} style={{margin: 0}}>
			<div className={'container'}>
				<h3>Make a Payment</h3>
				<span>We accept payments in the form of Google Pay, Venmo, and personal checks. Preferred payment
					methods are Google Pay, and Venmo.</span>
			</div>
			<div className={'payment-instructions container'}>
				<h4>Pay with Google Pay</h4>
				<div>
					If you haven't already, set up Google Pay by following the instructions&nbsp;
					<a href={'https://pay.google.com/about/'}>HERE</a>.
					You will need to have a Google account and set up a payment method.
				</div>
				<ol>
					<li>
						Sign in to Google Pay <a href={'https://pay.google.com/'}>HERE</a>.
					</li>
					<li>
						Navigate to Send or request money. And select Send Money.
					</li>
					<li>
						Enter your rent amount.
					</li>
					<li>
						Enter <b>ianballardproperties@gmail.com</b> as the recipient and
						your address and apartment # in the memo section.
					</li>
					<li>
						Click <b>Send</b>.
					</li>
				</ol>
			</div>
			<div className={'payment-instructions container'}>
				<h4>Pay with Venmo</h4>
				<div>
					If you haven't already, set up Venmo by following the instructions&nbsp;
					<a href={'https://help.venmo.com/hc/en-us/articles/209690068-How-to-Sign-Up'}>HERE</a>.
					You will need to download the mobile app, sign up, and link a bank account.
				</div>
				<ol>
					<li>
						Sign in to the Venmo app.
					</li>
					<li>
						Send your rent amount to <b>@IanBallard</b> with your address and apartment #
						in the 'What's it for?' section.
					</li>
					<li>
						Make the transaction private if you wish to hide your activity from the online world by
						clicking the 'public' world icon in the bottom right.
					</li>
					<li>
						Click <b>Pay</b>.
					</li>
				</ol>
			</div>
			<div className={'payment-instructions container'}>
				<h4>Pay By Check</h4>
				<ol>
					<li>
						Make a check out to <b>Ian Ballard</b> for your rent amount.
						Write in your address and apartment # in the memo section.
					</li>
					<li>
						<a href={'/contact'}>Contact Us</a> for our mailing address.
					</li>
					<li>
						Mail the check.
					</li>
				</ol>
				<div>
					<b>Note: </b> Be sure to mail it out so that it's <b><i>RECEIVED</i></b> by the 1st of each month.
				</div>
			</div>
		</div>
	);
};

export default Payments;
