import React, {Component} from 'react';
import {doApiRequest} from "../../api/requests";
import {TASK_LIST, UPDATE_TASK} from "./schema";
import List from "../../containers/common/List";
import moment from "moment";

class Tasks extends Component {

    state = {
        taskList: []
    }

    componentDidMount = async () => {
        await this.getTaskList();
    }

    async getTaskList() {
        try {
            const months = []
            months.push(
                moment().format('YYYY-MM'),
                moment().add(1, 'month').format('YYYY-MM'),
                moment().add(2, 'month').format('YYYY-MM'),
                moment().add(3, 'month').format('YYYY-MM'))
            for (const month of months) {
                const query = {...TASK_LIST};
                query['content']['ExpressionAttributeValues'][':data'] = 'OPEN#' + month
                const response = await doApiRequest(query);
                if (200 === response.status) {
                    const taskList = [...this.state.taskList]
                    const data = response.data.data;
                    for (let item of data) {
                        item['type'] = item['sk'].startsWith('PR') ? 'Property' : 'Tenant'
                    }
                    taskList.push.apply(taskList, data)
                    this.setState({taskList: taskList});
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    updateItemHandler = async (item) => {
        try {
            const update = {...UPDATE_TASK};
            update['content']['Key']['pk'] = item['pk']
            update['content']['Key']['sk'] = item['sk']
            update['content']['ExpressionAttributeValues'][':data'] = 'COMPLETE#' + moment().format('YYYY-MM-DD')
            const response = await doApiRequest(update);
            if (200 === response.status) {
                const taskList = [...this.state.taskList]
                const index = taskList.findIndex(t => t['pk'] === item['pk'] && t['sk'] === item['sk'])
                taskList.splice(index, 1)
                this.setState({taskList: taskList});
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return <div>
            <List
                items={this.state.taskList}
                title={'Open Tasks'}
                columns={['type', 'property_address', 'data', 'description']}
                baseHref={(item) => item['sk'].startsWith('PR') ? '/properties/' : '/tenants/'}
                id={'sk'}
                updateItem={this.updateItemHandler}
            />
        </div>
    }
}

export default Tasks;
