import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import React from "react";
import {withRouter} from "react-router";
import './style.css'

const NavBar = (props) => {

    return <Navbar expand="lg" id="navbar" variant="dark">
        <Navbar.Brand href="/" id='brand'>{window.innerWidth > 285 ? 'Ian Ballard Properties' : 'IBP'}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav activeKey={window.location.pathname} className="mr-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/listings">Listings</Nav.Link>
                {/*<Nav.Link href="/login" className={'nav-login'}>Admin Login</Nav.Link>*/}
                <Nav.Link href="/contact">Contact Us</Nav.Link>
                <Nav.Link href="/payments">Payments</Nav.Link>
                <Nav.Link href="/forms">Forms</Nav.Link>
                {localStorage.getItem("ID_TOKEN") && <Nav.Link href="/properties">Properties</Nav.Link>}
                {localStorage.getItem("ID_TOKEN") && <Nav.Link href="/transactions">Transactions</Nav.Link>}
                {localStorage.getItem("ID_TOKEN") && <Nav.Link href="/tasks">Tasks</Nav.Link>}
            </Nav>
        </Navbar.Collapse>
        {/*<a href={'/login'} className={'btn btn-primary btn-login'}>Admin Login</a>*/}
    </Navbar>
}

export default withRouter(NavBar)
