import axios from 'axios';

export const BASE_URL = 'https://byv1zi8ook.execute-api.us-east-1.amazonaws.com/Prod'
// export const BASE_URL = 'http://127.0.0.1:8080'

export async function doApiRequest(body) {
    let conf = { method: 'post', url: `/api`, data: body };
    return httpsAuth(conf);
}

export async function doAuthRequest(body) {
    let conf = { method: 'post', url: `/auth`, data: body };
    return https(conf);
}

export const https = axios.create({
    baseURL: BASE_URL
});

export const httpsAuth = axios.create({
    baseURL: BASE_URL
});

httpsAuth.interceptors.request.use(request => {
    request.headers['Authorization'] = localStorage.getItem('ID_TOKEN');
    const loader = document.getElementById('loader');
    loader.classList.remove("hide-loader");
    loader.classList.add("show-loader");
    return request
})

httpsAuth.interceptors.response.use(response => {
        const loader = document.getElementById('loader');
        loader.classList.remove("show-loader");
        loader.classList.add("hide-loader");
        return response
    },
    error => {
        const loader = document.getElementById('loader');
        loader.classList.remove("show-loader");
        loader.classList.add("hide-loader");
        return retryError(error)
    })

let isAlreadyFetchingAccessToken = false
let subscribers = []

const retryError = error => {
    const {config, response: {status}} = error;
    const originalRequest = config;

    if (status === 403) {
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            doAuthRequest({
                'operation': 'refresh',
                'REFRESH_TOKEN': localStorage.getItem('REFRESH_TOKEN')
            }).then(response => {
                if (response && response.data && response.data.data) {
                    const authData = response.data.data
                    localStorage.setItem("ID_TOKEN", authData.AuthenticationResult.IdToken);
                    isAlreadyFetchingAccessToken = false;
                    subscribers = subscribers.filter(callback => callback(authData.AuthenticationResult.IdToken))
                } else {
                    isAlreadyFetchingAccessToken = false;
                    alert('Unable to refresh token. Please login.');
                    window.location = 'https://ianballardproperties.com/admin'
                }
            }).catch(err => {
                console.log(err)
                isAlreadyFetchingAccessToken = false;
                alert('Unable to refresh token. Please login.');
                window.location = 'https://ianballardproperties.com/admin'
            })
        }

        return new Promise((resolve) => {
            addSubscriber(cb => {
                originalRequest.headers.Authorization = localStorage.getItem('ID_TOKEN');
                resolve(httpsAuth(originalRequest))
            })
        })
    }

    if (status !== 403) {
        alert(error);
    }

    return Promise.reject(error)
}

const addSubscriber = callback => {
    subscribers.push(callback)
};
