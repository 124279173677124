export const TRANSACTION_LIST = {
    "operation": "query",
    "content": {
        "ProjectionExpression": "#data, amt_due, pk, sk, tenant_name, tenant_mobile, property_address, property_unit, reason",
        "ExpressionAttributeNames": {
            "#data": "data"
        },
        "KeyConditionExpression": "entity_type = :entity_type and begins_with(#data, :data)",
        "ExpressionAttributeValues": {
            ":entity_type": "TR",
            ":data": null
        },
        "IndexName": "entity_type-gsi"
    }
};

export const UPDATE_TRANSACTION = {
    "operation": "update",
        "content": {
        "Key": {
            "pk": null,
            "sk": null
        },
        "UpdateExpression": "set #data=:data",
            "ExpressionAttributeNames": {
            "#data": "data"
        },
        "ExpressionAttributeValues": {
            ":data": null
        }
    }
};
