import React from "react";
import "./style.css"
import Modal from "react-bootstrap/Modal";
import {toTitleCase} from "../../utils/StringUtil";
import Form from "react-bootstrap/Form";
import {SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

const CustomModal = (props) => {
    return <Modal show={props.showModal} onHide={props.closeModal}>
        <Form onSubmit={props.submitHandler} >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'row'}>
                    {Object
                        .entries(props.data)
                        .map(entry => {
                            if (entry[0].includes('date')) {
                                return <div className={'col-lg-6 col-12'}>
                                    <Form.Group controlId={`${entry[0]}`} key={entry[0]}>
                                        <Form.Label>{toTitleCase(entry[0])}</Form.Label>
                                        <SingleDatePicker
                                            date={props.date} // momentPropTypes.momentObj or null
                                            onDateChange={props.handleDateChange} // PropTypes.func.isRequired
                                            focused={props.focused} // PropTypes.bool
                                            onFocusChange={({ focused }) => props.handleDateFocusChange(focused)} // PropTypes.func.isRequired
                                        />
                                    </Form.Group>
                                </div>
                            }
                            return <div className={'col-lg-6 col-12'}>
                                <Form.Group controlId={`${entry[0]}`} key={entry[0]}>
                                    <Form.Label>{toTitleCase(entry[0])}</Form.Label>
                                    <Form.Control
                                        id={`${entry[0]}`}
                                        name={`${entry[0]}`}
                                        type={`${entry[0]}` === 'password' ? 'password' : 'text'}
                                        onChange={(e) => props.onChange(e, entry[0])}/>
                                </Form.Group>
                            </div>
                        })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className={'btn btn-outline-secondary'} onClick={props.closeModal}>
                    Close
                </button>
                <button className={'btn btn-outline-primary'} type={'submit'}>
                    {props.submitText ? props.submitText : 'Save' }
                </button>
            </Modal.Footer>
        </Form>
    </Modal>
}

export default CustomModal;
