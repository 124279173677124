import React, {Component} from 'react';
import {doApiRequest} from "../../api/requests";
import {PROPERTY_LIST} from "./schema";
import List from "../../containers/common/List";
import CustomModal from "../../containers/common/Modal";
import {v4 as uuidv4} from 'uuid';

class Properties extends Component {

    state = {
        propertyList: [],
        showModal: false,
        newProperty: {
            data: '',
            address_city: ''
        }
    }

    componentDidMount = async () => {
        await this.getPropertyList();
    }

    async getPropertyList() {
        try {
            const response = await doApiRequest(PROPERTY_LIST);
            if (200 === response.status) {
                this.setState({propertyList: response.data.data});
            }
        } catch (e) {
            console.log(e)
        }
    }

    onChange = (e, field) => {
        const newState = {...this.state};
        newState['newProperty'][field] = e.target.value;
        this.setState(newState)
    }

    submitHandler = async (e) => {
        e.preventDefault()
        try {
            const createProperty = {
                operation: 'put',
                content: {...this.state.newProperty}
            }
            createProperty['content']['pk'] = 'PR-' + uuidv4()
            createProperty['content']['sk'] = 'PR'
            createProperty['content']['entity_type'] = 'PR'
            const response = await doApiRequest(createProperty);
            if (201 === response.status) {
                await this.getPropertyList();
            }
        } catch (e) {
            console.log(e)
        }
        this.setState({showModal: false})
    }

    render() {
        return <div>
            <CustomModal
                title={'New Property'}
                showModal={this.state.showModal}
                closeModal={() => this.setState({showModal: false})}
                submitHandler={this.submitHandler}
                data={this.state.newProperty}
                onChange={this.onChange}
            />
            <List
                items={this.state.propertyList}
                title={'Properties'}
                columns={['data', 'address_city']}
                baseHref={'/properties/'}
                id={'pk'}
                openModal={() => this.setState({showModal: true})}
            />
        </div>
    }
}

export default Properties;
