import React from "react";
import "./style.css"
import {toTitleCase} from "../../utils/StringUtil";

const List = (props) => {
    const isFunction = (varToCheck) => {
        return varToCheck && {}.toString.call(varToCheck) === '[object Function]';
    }
    if (props.updateItem) {
        props.columns.push('action')
    }
    const columnLength = 'col-lg-' + Math.floor(12 / props.columns.length)
    return <div className={'container list-container'}>
        <div className={'d-flex justify-content-between title'}>
            <h4>{props.title}</h4>
            {props.openModal &&
            <button className={'mb-1 btn btn-outline-primary'} type={'button'} onClick={props.openModal}>
                New
            </button>}
        </div>
        <div className={'row container-inner list-body'}>
            <div className={'col-12'}>
                <div className={'row list-header list-item'} >
                    {props.columns.map(column => {
                        return <div className={columnLength} key={column}>
                            {toTitleCase(column)}
                        </div>})}
                </div>
                {props.items.map(item => {
                    return <div className={'row list-item'}>
                        {props.columns.map(column => {
                            if (column === 'data' && props.baseHref && props.id) {
                                const baseHref = isFunction(props.baseHref) ? props.baseHref(item) : props.baseHref
                                return <div
                                    key={item[column]}
                                    className={columnLength}>
                                    <a href={`${baseHref}${item[props.id]}`}>
                                        {item[column]}
                                    </a>
                                </div>
                            }
                            if (column === 'action') {
                                return <div key={item[column]} className={columnLength}>
                                    <button className={'my-1 btn btn-outline-primary'}
                                            type={'button'}
                                            onClick={() => props.updateItem(item)}>
                                        Update
                                    </button>
                                </div>
                            }
                            if (column === 'tenant_mobile' || column === 'mobile') {
                                return <div key={item[column]} className={columnLength}>
                                    <a href={'sms:+1'+item[column]}>{item[column]}</a>
                                </div>
                            }
                            return <div key={item[column]} className={columnLength}>{item[column]}</div>
                        })}
                    </div>
                })}
            </div>
        </div>
    </div>
}

export default List;
