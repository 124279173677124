export const UNIT_DETAILS = {
    operation: "query",
    content: {
        ProjectionExpression: "pk, sk, #data, #status, city, property_address, sq_ft, num_bedrooms, num_bathrooms, " +
            "description, features, kitchen, asset_locations, year_built, lease_length, rent, property_packet_location",
        ExpressionAttributeNames: {
            "#data": "data",
            "#status":"status"
        },
        KeyConditionExpression: "pk = :pk",
        ExpressionAttributeValues: {
            ":pk": null
        }
    }
};

