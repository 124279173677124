import React from 'react';
import './style.css';

const Contact = (props) => {
	return (
		<div className={'row'} style={{margin: 0}}>
			<div className={'container'}>
				<h4>Contact Us</h4>
				<b>Business hours: <br/>
					10am - 4pm, Monday - Friday</b><br/>
				For all rental inquiries and non-emergency maintenance needs please contact our office:
				<div>
					Phone:&nbsp;
					<a href={'tel:+1-518-952-0054'}>(518) 952-0054</a>
				</div>
				<div>
					Email:&nbsp;
					<a href={'mailto:support@ianballardproperties.com'}>ianballardproperties@gmail.com</a>
				</div>
			</div>

			<div className={'container'}>
				<h3>Disclaimer</h3>
				<div className={'contact-disclaimer'}>
					If you think you have a medical, fire, or life threatening emergency, call your doctor or 911 immediately.
					Do not rely on electronic communications or communication through this website for immediate,
					urgent medical, fire, or life threatening needs. This website is not designed to facilitate medical,
					police, or fire emergencies.
				</div>
			</div>
		</div>
	);
};

export default Contact;
