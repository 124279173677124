import React, {Component} from 'react';
import {doApiRequest} from "../../api/requests";
import {LIST, PROPERTY_DETAILS} from "./schema";
import {withRouter} from "react-router";
import PrimaryDetails from "../../containers/common/PrimaryDetails";
import List from "../../containers/common/List";
import CustomModal from "../../containers/common/Modal";
import {v4 as uuidv4} from "uuid";
import {UPDATE_TASK} from "../Tasks/schema";
import moment from "moment";

class Property extends Component {

    state = {
        property: {
            data: '',
            address_city: '',
        },
        unitList: [],
        taskList: [],
        showUnitModal: false,
        showTaskModal: false,
        newUnit: {
            data: '',
            num_bedrooms: '',
            occupied: ''
        },
        newTask: {
            due_date: moment().format('YYYY-MM-DD'),
            description: ''
        },
        datePicker: {
            focus: false,
            date: moment()
        }
    }

    componentDidMount = async () => {
        await this.getProperty();
        await this.getLists();
    }

    async getProperty() {
        try {
            const propertyId = this.props.match.params['propertyId']
            const body = {...PROPERTY_DETAILS};
            body['content']['ExpressionAttributeValues'][':pk'] = propertyId;
            const response = await doApiRequest(body);
            if (200 === response.status) {
                this.setState({property: response.data.data[0]})
            }
        } catch (e) {
            console.log(e)
        }
    }

    async getLists() {
        try {
            const propertyId = this.props.match.params['propertyId']
            const body = {...LIST};
            body['content']['ExpressionAttributeValues'][':sk'] = propertyId;
            const response = await doApiRequest(body);
            if (200 === response.status) {
                const unitList = [];
                const taskList = [];
                for (let item of response.data.data) {
                    if (item['entity_type'] === 'UN') {
                        unitList.push(item)
                    } else if (item['entity_type'] === 'TA'){
                        taskList.push(item)
                    }
                }
                this.setState({unitList: unitList, taskList: taskList})
            }
        } catch (e) {
            console.log(e)
        }
    }

    onUnitChange = (e, field) => {
        const newState = {...this.state};
        newState['newUnit'][field] = e.target.value;
        this.setState(newState)
    }

    onTaskChange = (e, field) => {
        const newState = {...this.state};
        newState['newTask'][field] = e.target.value;
        this.setState(newState)
    }

    handleDateChange = (value) => {
        const newState = {...this.state};
        newState['datePicker']['date'] = value
        newState['newTask']['due_date'] = value.format('YYYY-MM-DD')
        this.setState(newState);
    }

    handleDateFocusChange = (value) => {
        const newState = {...this.state};
        newState['datePicker']['focus'] = value
        this.setState(newState);
    }

    submitUnitHandler = async (e) => {
        e.preventDefault()
        try {
            const createUnit = {
                operation: 'put',
                content: {...this.state.newUnit}
            }
            createUnit['content']['pk'] = 'UN-' + uuidv4();
            createUnit['content']['sk'] = this.state.property.pk;
            createUnit['content']['entity_type'] = 'UN'
            createUnit['content']['property_address'] = this.state.property.data
            const response = await doApiRequest(createUnit);
            if (201 === response.status) {
                await this.getLists();
            }
        } catch (e) {
            console.log(e)
        }
        this.setState({showUnitModal: false})
    }

    submitTaskHandler = async (e) => {
        e.preventDefault()
        try {
            const createTask = {
                operation: 'put',
                content: {...this.state.newTask}
            }
            createTask['content']['data'] = 'OPEN#' + createTask['content']['due_date']
            createTask['content']['pk'] = 'TA-' + uuidv4();
            createTask['content']['sk'] = this.state.property.pk;
            createTask['content']['entity_type'] = 'TA'
            createTask['content']['property_address'] = this.state.property.data
            const response = await doApiRequest(createTask);
            if (201 === response.status) {
                await this.getLists();
            }
        } catch (e) {
            console.log(e)
        }
        this.setState({showTaskModal: false})
    }

    updateItemHandler = async (item) => {
        try {
            const update = {...UPDATE_TASK};
            update['content']['Key']['pk'] = item['pk']
            update['content']['Key']['sk'] = item['sk']
            update['content']['ExpressionAttributeValues'][':data'] = 'COMPLETE#' + moment().format('YYYY-MM-DD')
            const response = await doApiRequest(update);
            if (200 === response.status) {
                const taskList = [...this.state.taskList]
                const index = taskList.findIndex(t => t['pk'] === item['pk'] && t['sk'] === item['sk'])
                taskList[index].data = update['content']['ExpressionAttributeValues'][':data']
                this.setState({taskList: taskList});
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return <div>
            <CustomModal
                title={'New Unit'}
                showModal={this.state.showUnitModal}
                closeModal={() => this.setState({showUnitModal: false})}
                submitHandler={this.submitUnitHandler}
                data={this.state.newUnit}
                onChange={this.onUnitChange}
            />
            <CustomModal
                title={'New Task'}
                showModal={this.state.showTaskModal}
                closeModal={() => this.setState({showTaskModal: false})}
                submitHandler={this.submitTaskHandler}
                data={this.state.newTask}
                onChange={this.onTaskChange}
                date={this.state.datePicker.date}
                focused={this.state.datePicker.focus}
                handleDateChange={this.handleDateChange}
                handleDateFocusChange={this.handleDateFocusChange}
            />
            <PrimaryDetails details={this.state.property} title={'Property Details'}/>
            <List
                items={this.state.unitList}
                title={'Units'}
                columns={['data', 'num_bedrooms', 'occupied']}
                baseHref={'/units/'}
                id={'pk'}
                openModal={() => this.setState({showUnitModal: true})}
            />
            <List
                items={this.state.taskList}
                title={'Tasks'}
                columns={['data', 'description']}
                updateItem={this.updateItemHandler}
                openModal={() => this.setState({showTaskModal: true})}
            />
        </div>

    }
}

export default withRouter(Property);
