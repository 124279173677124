import React, {Component} from 'react';
import {doApiRequest} from "../../api/requests";
import {UNIT_DETAILS} from "./schema";
import {withRouter} from "react-router";
import Carousel from "react-bootstrap/Carousel";
import {STORAGE_PREFIX} from "../../App";
import moment from "moment";
import './style.css';

class Listing extends Component {

    state = {
        unit: {
            data: '',
            city: '',
            property_address: '',
            sq_ft: '',
            num_bedrooms: '',
            num_bathrooms: '',
            year_built: '',
            features: [],
            kitchen: [],
            asset_locations: [],
            status: '',
            description: '',
            lease_length: '',
            rent: '',
            property_packet_location: ''
        }
    }

    componentDidMount = async () => {
        await this.getUnit();
    }

    async getUnit() {
        try {
            let listingId = this.props.match.params['listingId']
            if (listingId === 'latest') {
                listingId = 'UN-5decef58-b341-43fc-a7ee-d3fa5c61574f'
            }
            const body = {...UNIT_DETAILS};
            body['content']['ExpressionAttributeValues'][':pk'] = listingId;
            const response = await doApiRequest(body);
            if (200 === response.status) {
                const unit = response.data.data[0]
                unit['features'] = unit['features'].split(',')
                unit['kitchen'] = unit['kitchen'].split(',')
                this.setState({unit: unit})
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const mapUrl = 'https://www.google.com/maps/place/' +
            this.state.unit.property_address.replace(' ', '+') + ',+' +
            this.state.unit.city.replace(' ', '+')
        return <div>
            <div className={'container'}>
                <div className={'d-lg-none d-block justify-content-center'}>
                    {getStatusBadge(this.state.unit.status)}
                    <div className={'d-flex mt-2 contact-buttons justify-content-end'}>
                        <div className={''}>
                            <button className="btn btn-outline-primary"
                                    type="button"
                                    onClick={() =>
                                    {window.location = 'sms:+1-518-952-0054'}}>
                                <span className={'listing-action'}>Text</span> <i className="fa fa-mobile" style={{fontSize:20}}></i>
                            </button>
                        </div>&nbsp;
                        <div className={''}>
                            <button className="btn btn-outline-primary"
                                    type="button"
                                    onClick={() =>
                                    {window.location = 'tel:+1-518-952-0054'}}>
                                <span className={'listing-action'}>Call</span> <i className="fa fa-phone"></i>
                            </button>
                        </div>&nbsp;
                        <div className={''}>
                            <button className="btn btn-outline-primary"
                                    type="button"
                                    onClick={() =>
                                    {window.location = 'mailto:ianballardproperties@gmail.com'}}>
                                <span className={'listing-action'}>Email</span> <i className="fa fa-envelope"></i>
                            </button>
                        </div>&nbsp;
                        <div className={''}>
                            <button className="btn btn-outline-primary"
                                    type="button"
                                    onClick={() =>
                                    {window.open(STORAGE_PREFIX + '/packets/' +
                                        this.state.unit.property_packet_location)}}>
                                <span className={'listing-action'}>Print</span> <i className="fa fa-print"></i>
                            </button>
                        </div>&nbsp;
                        <button className="btn btn-outline-primary"
                                type="button"
                                onClick={() => {window.open(mapUrl)}}>
                            <span className={'listing-action'}>Map</span> <i className="fa fa-map-marker"></i>
                        </button>
                    </div>
                </div>
                <div className={'d-lg-flex d-none justify-content-between'}>
                    {getStatusBadge(this.state.unit.status)}
                    <div>
                        <a href={'sms:+1-518-952-0054'} className="btn btn-outline-primary" role="button">
                            Text <i className="fa fa-mobile" style={{fontSize:20}}></i>
                        </a>&nbsp;
                        <a href={'tel:+1-518-952-0054'} className="btn btn-outline-primary" role="button">
                            Call <i className="fa fa-phone"></i>
                        </a>&nbsp;
                        <a href={'mailto:ianballardproperties@gmail.com'} className="btn btn-outline-primary" role="button">
                            Email <i className="fa fa-envelope"></i>
                        </a>&nbsp;
                        <button className="btn btn-outline-primary"
                                type="button"
                                onClick={() =>
                                    {window.open(STORAGE_PREFIX + '/packets/' +
                                        this.state.unit.property_packet_location)}}>
                            Print <i className="fa fa-print"></i>
                        </button>&nbsp;
                        <button className="btn btn-outline-primary"
                                type="button"
                                onClick={() => {window.open(mapUrl)}}>
                            Map <i className="fa fa-map-marker"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <div className={'title'}>
                    <h4>{this.state.unit.property_address + ' - ' + this.state.unit.data}</h4>
                    <h4>{this.state.unit.city}</h4>
                </div>
                    <Carousel>
                        {this.state.unit.asset_locations.map(location =>
                            <Carousel.Item >
                                <img
                                    className="d-block carousel-image mx-auto"
                                    src={`${STORAGE_PREFIX}/images/${location}`}
                                    alt={location}
                                />
                            </Carousel.Item>
                        )}
                    </Carousel>
            </div>
            <div className={'container list-container'}>
                <div className={'row container-inner list-body'}>
                    <div className={'col-12'}>
                        <div className={'row list-header list-item'} >
                            <div className={'col-3'}>Rent</div>
                            <div className={'col-3'}>Beds</div>
                            <div className={'col-3'}>Baths</div>
                            <div className={'col-3'}>SqFt</div>
                        </div>
                        <div className={'row list-item'}>
                            <div className={'col-3'}>{'$' + this.state.unit.rent}</div>
                            <div className={'col-3'}>{this.state.unit.num_bedrooms}</div>
                            <div className={'col-3'}>{this.state.unit.num_bathrooms}</div>
                            <div className={'col-3'}>{this.state.unit.sq_ft}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <h4 className={'title'}>{'About ' + this.state.unit.property_address}</h4>
                <div className={'mt-1'}>{this.state.unit.description}</div>
            </div>
            <div className={'container'}>
                <h4 className={'title'}>Amenities</h4>
                <div className={'row mt-1'}>
                    <div className={'col-lg-4 col-12'}>
                        <h5 className={'ml-lg-3'}>Features</h5>
                        <ul>
                            {this.state.unit.features.map(feature =>
                            <li key={feature}>
                                {feature}
                            </li>)}
                        </ul>
                    </div>
                    <div className={'col-lg-4 col-12'}>
                        <h5 className={'ml-lg-3'}>Kitchen</h5>
                        <ul>
                            {this.state.unit.kitchen.map(kitchen =>
                                <li key={kitchen}>
                                    {kitchen}
                                </li>)}
                        </ul>
                    </div>
                    <div className={'col-lg-4 col-12'}>
                        <h5 className={'ml-lg-3'}>Lease Length</h5>
                        <ul>
                            <li>
                                {this.state.unit.lease_length}
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    }
}

export default withRouter(Listing);


export const getStatusBadge = (status) => {
    const statusParts = status.split('#')
    if (statusParts[0] === 'INACTIVE') {
        return <div className={'status-badge status-badge-inactive'}>
            Unavailable
        </div>
    } else if (statusParts[0] === 'ACTIVE' && moment(statusParts[1], 'YYYY-MM-DD') > moment()) {
        return <div className={'status-badge status-badge-inactive'}>
            Available {moment(statusParts[1], 'YYYY-MM-DD').format('MM/DD/YYYY')}
        </div>
    } else if (statusParts[0] === 'ACTIVE' && moment(statusParts[1], 'YYYY-MM-DD') <= moment()) {
        return <div className={'status-badge status-badge-active'}>
            Available Now
        </div>
    } else {
        return <div/>
    }
}
