import React, {Component} from 'react';
import {doApiRequest} from "../../api/requests";
import {TRANSACTION_LIST, UPDATE_TRANSACTION} from "./schema";
import List from "../../containers/common/List";
import moment from "moment";

class Transactions extends Component {

    state = {
        transactionList: []
    }

    componentDidMount = async () => {
        await this.getTransactionList();
    }

    async getTransactionList() {
        try {
            const months = []
            months.push(
                moment().subtract(1, 'month').format('YYYY-MM'),
                moment().format('YYYY-MM'),
                moment().add(1, 'month').format('YYYY-MM'))
            for (const month of months) {
                const query = {...TRANSACTION_LIST};
                query['content']['ExpressionAttributeValues'][':data'] = 'OPEN#' + month
                const response = await doApiRequest(query);
                if (200 === response.status) {
                    const transactionList = [...this.state.transactionList]
                    transactionList.push.apply(transactionList, response.data.data)
                    this.setState({transactionList: transactionList});
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    updateItemHandler = async (item) => {
        try {
            const update = {...UPDATE_TRANSACTION};
            update['content']['Key']['pk'] = item['pk']
            update['content']['Key']['sk'] = item['sk']
            update['content']['ExpressionAttributeValues'][':data'] = 'PAID#' + moment().format('YYYY-MM-DD')
            const response = await doApiRequest(update);
            if (200 === response.status) {
                const transactionList = [...this.state.transactionList]
                const index = transactionList.findIndex(t => t['pk'] === item['pk'] && t['sk'] === item['sk'])
                transactionList.splice(index, 1)
                this.setState({transactionList: transactionList});
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return <div>
            <List
                items={this.state.transactionList}
                title={'Open Transactions'}
                columns={['tenant_name', 'property_address', 'tenant_mobile', 'data', 'reason']}
                baseHref={'/leases/'}
                id={'sk'}
                updateItem={this.updateItemHandler}
            />
        </div>
    }
}

export default Transactions;
