import React from 'react';
import './style.css';
import Carousel from "react-bootstrap/Carousel";
import {withRouter} from "react-router";
import {STORAGE_PREFIX} from "../../App";

const Home = (props) => {
	return (
		<div className={'row'} style={{margin: 0}}>
			<div className={'container'} style={{textAlign: 'center'}}>
				<div className={'col-12'}>
					<h3>Welcome to Ian Ballard Properties</h3>
					<div>
						We are proud to have the best apartments to rent in the Saratoga, Albany, Schenectady, Troy area. <br/>
						If you are searching for the perfect rental home, look no further. <br/>
						Check out our current&nbsp;
						<a href={'/listings'}>Listings</a>, and <a href={'/contact'}>Contact Us</a> today!
					</div>
				</div>
			</div>
			<div className={'container'} style={{textAlign: 'center'}}>
				<Carousel>
					<Carousel.Item>
						<img
							onClick={() => props.history.push(`/listings/UN-1e537925-defb-4793-8a10-4f0da2619957`)}
							className="d-block carousel-image mx-auto"
							src={`${STORAGE_PREFIX}/images/79_Petrified_Sea_Gardens_Rd/01.jpg`}
							alt="1st slide"
						/>
						<Carousel.Caption className="d-lg-block d-none">
							<h3>Saratoga Springs</h3>
						</Carousel.Caption>
					</Carousel.Item>
					<Carousel.Item>
						<img
							onClick={() => props.history.push(`/listings/UN-1e537925-defb-4793-8a10-4f0da2619957`)}
							className="d-block carousel-image mx-auto"
							src={`${STORAGE_PREFIX}/images/79_Petrified_Sea_Gardens_Rd/02.jpg`}
							alt="2nd slide"
						/>
					</Carousel.Item>
					<Carousel.Item>
						<img
							onClick={() => props.history.push(`/listings/UN-1e537925-defb-4793-8a10-4f0da2619957`)}
							className="d-block carousel-image mx-auto"
							src={`${STORAGE_PREFIX}/images/79_Petrified_Sea_Gardens_Rd/03.jpg`}
							alt="3rd slide"
						/>
					</Carousel.Item>
					<Carousel.Item>
						<img
							onClick={() => props.history.push(`/listings/UN-1e537925-defb-4793-8a10-4f0da2619957`)}
							className="d-block carousel-image mx-auto"
							src={`${STORAGE_PREFIX}/images/79_Petrified_Sea_Gardens_Rd/04.jpg`}
							alt="4th slide"
						/>
					</Carousel.Item>
					<Carousel.Item>
						<img
							onClick={() => props.history.push(`/listings/UN-1e537925-defb-4793-8a10-4f0da2619957`)}
							className="d-block carousel-image mx-auto"
							src={`${STORAGE_PREFIX}/images/79_Petrified_Sea_Gardens_Rd/05.jpg`}
							alt="5th slide"
						/>
					</Carousel.Item>
					<Carousel.Item>
						<img
							onClick={() => props.history.push(`/listings/UN-1e537925-defb-4793-8a10-4f0da2619957`)}
							className="d-block carousel-image mx-auto"
							src={`${STORAGE_PREFIX}/images/79_Petrified_Sea_Gardens_Rd/06.jpg`}
							alt="6th slide"
						/>
					</Carousel.Item>
					<Carousel.Item>
						<img
							onClick={() => props.history.push(`/listings/UN-1e537925-defb-4793-8a10-4f0da2619957`)}
							className="d-block carousel-image mx-auto"
							src={`${STORAGE_PREFIX}/images/79_Petrified_Sea_Gardens_Rd/07.jpg`}
							alt="7th slide"
						/>
					</Carousel.Item>
					<Carousel.Item>
						<img
							onClick={() => props.history.push(`/listings/UN-1e537925-defb-4793-8a10-4f0da2619957`)}
							className="d-block carousel-image mx-auto"
							src={`${STORAGE_PREFIX}/images/79_Petrified_Sea_Gardens_Rd/08.jpg`}
							alt="8th slide"
						/>
					</Carousel.Item>
					<Carousel.Item>
						<img
							onClick={() => props.history.push(`/listings/UN-1e537925-defb-4793-8a10-4f0da2619957`)}
							className="d-block carousel-image mx-auto"
							src={`${STORAGE_PREFIX}/images/79_Petrified_Sea_Gardens_Rd/09.jpg`}
							alt="9th slide"
						/>
					</Carousel.Item>
				</Carousel>
			</div>
			<div className={'container'} style={{textAlign: 'center'}}>
				<div>
					Ian Ballard Properties owns and manages premier rental properties
					throughout New York's capital region. It is our pleasure to provide, maintain, and improve
					affordable homes with exceptional service to our tenants.
				</div>
			</div>
		</div>
	);
};

export default withRouter(Home);
