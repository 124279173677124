import React from "react";
import './style.css'
import { SocialIcon } from 'react-social-icons';

const Footer = (props) => {

    return <div id={'footer'}>
        <SocialIcon url="https://www.instagram.com/ianballardproperties" className={'social-icon'}/>
    </div>
}

export default Footer;
