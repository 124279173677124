export const PROPERTY_LIST = {
    operation: "query",
    content: {
        ProjectionExpression: "pk, sk, #data, address_city",
        ExpressionAttributeNames: {
            "#data": "data"
        },
        KeyConditionExpression: "sk = :sk",
        ExpressionAttributeValues: {
            ":sk": "PR"
        },
        IndexName: "gsi-1"
    }
};

